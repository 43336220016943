import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import {
  ActivityDrawer,
  Avatar,
  Badge,
  ContextMenu,
  CreateButton,
  Icon,
  InviteTeammateModal,
  Tooltip,
} from "..";
import { useAuth } from "../../../../state/AuthContexts";
import { formatDateForRailz, getERPName } from "../../helpers";
import { useBillCountOnNavigate } from "../../hooks";

const navItems = [
  {
    name: "Home",
    href: "/setup",
    current: false,
    iconCount: 0,
  },
  {
    name: "Invoices",
    href: "/invoices",
    current: false,
    iconCount: 0,
  },
  {
    name: "Bills",
    href: "/bills",
    current: false,
    iconCount: 0,
  },
  {
    name: "Contacts",
    href: "/contacts",
    current: false,
    iconCount: 0,
  },
];

export default function NavBar() {
  const router = useRouter();
  const { user, company, logout } = useAuth();
  const newBillsCount = useBillCountOnNavigate();

  const [openContactModal, setOpenContactModal] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [navigation, setNavigation] = useState(navItems);
  const [openInviteDrawer, setOpenInviteDrawer] = useState(false);
  const [showActivity, setShowActivity] = useState(false);
  const [erpUpToDate, setERPUpToDate] = useState(
    company?.Connections && company?.Connections.length > 0
  );

  useEffect(() => {
    if (router.asPath) {
      setNavigation(
        navItems.map((item) => ({
          ...item,
          current: router.asPath.includes(item.href),
        }))
      );
    }
  }, [router.asPath]);

  useEffect(() => {
    setERPUpToDate(company?.Connections && company?.Connections.length > 0);
  }, [company?.Connections]);

  const showOnboarding = company
    ? !company?.OnboardingStatus?.onboarded
    : false;

  const showIntegrations =
    company?.Connections?.[0] &&
    company?.Connections &&
    company?.Connections.length > 0;

  const desktopMenuitems = [
    {
      label: "My profile",
      icon: "user",
      onClick: () => router.push("/profile"),
    },
    {
      label: "Company",
      icon: "briefcase",
      onClick: () => router.push("/profile/company"),
    },
    {
      label: "Team",
      icon: "users",
      onClick: () => router.push("/profile/team"),
    },
    {
      label: "Workflows",
      icon: "path",
      onClick: () => router.push("/profile/workflows"),
    },
    {
      label: "Banking",
      icon: "credit-card",
      onClick: () => router.push("/profile/banking"),
    },
    {
      label: "Integrations",
      icon: "arrows-clockwise",
      onClick: () => router.push("/profile/integrations"),
    },
    {
      label: "Company documents",
      icon: "signature",
      onClick: () => router.push("/profile/documents"),
    },
    {
      label: "Pay cycles",
      icon: "calendar-x",
      onClick: () => router.push("/profile/paycycles"),
    },
    {
      label: "Notifications",
      icon: "bell",
      onClick: () => router.push("/profile/notifications"),
    },
    {
      label: "Help and resources",
      icon: "headset",
      onClick: () => {
        window && window.Intercom && window.Intercom("show");
      },
    },
    {
      label: "Log out",
      icon: "sign-out",
      onClick: () => {
        logout();
      },
    },
  ];

  const mobileMenuItems = [
    {
      label: "Create",
      custom: (
        <li className="px-4 py-2" key="create-button">
          <CreateButton
            open={openContactModal}
            setOpen={() => {
              setOpenContactModal((s) => !s);
              setOpenMobileMenu((s) => !s);
            }}
            setCloseMobile={() => setOpenMobileMenu((s) => !s)}
          />
        </li>
      ),
    },
    {
      label: "Invoices",
      icon: "file-text",
      onClick: () => router.push("/invoices"),
    },
    {
      label: "Bills",
      icon: "archive-tray",
      onClick: () => router.push("/bills"),
    },
    {
      label: "Contacts",
      icon: "address-book",
      onClick: () => router.push("/contacts"),
      border: true,
    },
    {
      label: "Finish setup",
      icon: "check-circle",
      onClick: () => router.push("/setup"),
      border: showOnboarding,
      hidden: !showOnboarding,
    },
    {
      label: "My profile",
      icon: "user",
      onClick: () => router.push("/profile"),
    },
    {
      label: "Company",
      icon: "briefcase",
      onClick: () => router.push("/profile/company"),
    },
    {
      label: "Team",
      icon: "users",
      onClick: () => router.push("/profile/team"),
    },
    {
      label: "Workflows",
      icon: "path",
      onClick: () => router.push("/profile/workflows"),
    },
    {
      label: "Banking",
      icon: "credit-card",
      onClick: () => router.push("/profile/banking"),
    },
    {
      label: "Integrations",
      icon: "arrows-clockwise",
      onClick: () => router.push("/profile/integrations"),
    },
    {
      label: "Company documents",
      icon: "signature",
      onClick: () => router.push("/profile/documents"),
    },
    {
      label: "Pay cycles",
      icon: "calendar-x",
      onClick: () => router.push("/profile/paycycles"),
    },
    {
      label: "Notifications",
      icon: "bell",
      onClick: () => router.push("/profile/notifications"),
    },
    {
      label: "Help and resources",
      icon: "chat-circle",
      onClick: () => {
        window && window.Intercom && window.Intercom("show");
      },
      border: true,
    },
    {
      label: "Log out",
      icon: "sign-out",
      onClick: () => {
        logout();
      },
    },
  ];

  const getERPText = () => {
    if (company?.Connections && company?.Connections.length > 0) {
      if (erpUpToDate) {
        return `Successfully synced to ${getERPName(company?.Connections?.[0])}\n${
          company?.last_sync_at
            ? `Last sync: ${formatDateForRailz(company?.last_sync_at)}`
            : ""
        }`;
      } else {
        return `Failed to sync to ${erpUpToDate}. Please try again`;
      }
    } else {
      return "Connect ERP to get started";
    }
  };

  return (
    <div className="NavBar" data-testid="navbar">
      <div
        className="NavBar-Desktop z-20 hidden w-[60px] md:fixed md:inset-y-0 md:left-0 md:flex md:flex-col"
        data-testid="desktop-navbar"
      >
        <div className="flex flex-1 flex-col items-center justify-between border-r border-gray-200 bg-white">
          <div className="Navbar-top flex flex-col items-center">
            <div className="QuicklyLogo mt-4 h-8 w-8">
              <Icon icon="bolt-gradient" size="32" />
            </div>
            <div className="mb-4 mt-4">
              <CreateButton
                justIcon
                open={openContactModal}
                setOpen={setOpenContactModal}
              />
            </div>

            <nav className="Navbar-FirstMenu flex flex-col space-y-2 ">
              {/* INVOICES */}
              <Tooltip title="Invoices" position="right">
                <Link
                  href="/invoices"
                  aria-label="Invoices"
                  className={clsx(
                    "group flex h-10 w-10 items-center justify-center rounded-lg hover:bg-gray-100 focus-visible:outline focus-visible:outline-offset-0 focus-visible:outline-gray-900 group-hover:cursor-pointer",
                    {
                      "bg-gray-100": navigation[1].current,
                    }
                  )}
                >
                  <Icon
                    icon="file-text"
                    size="20"
                    iconStyle={`group-hover:fill-gray-900 ${
                      navigation[1].current ? "fill-gray-900" : "fill-gray-500"
                    }`}
                  />
                </Link>
              </Tooltip>
              {/* BILLS */}
              <Tooltip title="Bills" position="right">
                <Link
                  href="/bills"
                  className={clsx(
                    "group flex h-10 w-10 items-center justify-center rounded-lg hover:bg-gray-100 focus-visible:outline focus-visible:outline-offset-0 focus-visible:outline-gray-900 group-hover:cursor-pointer",
                    {
                      "bg-gray-100": navigation[2].current,
                    }
                  )}
                >
                  <span className="sr-only">Navigate to bills page</span>
                  <Icon
                    icon="currency-circle-dollar-custom"
                    size="20"
                    iconStyle={`group-hover:fill-gray-900 ${
                      navigation[2].current ? "fill-gray-900" : "fill-gray-500"
                    }`}
                  />

                  {!!newBillsCount && newBillsCount > 0 && (
                    <div className="absolute -top-1 right-0 w-3">
                      <Badge
                        type="number"
                        color="number"
                        label={
                          newBillsCount >= 10 ? "9+" : newBillsCount.toString()
                        }
                      />
                    </div>
                  )}
                </Link>
              </Tooltip>
              {/* CONTACTS */}
              <Tooltip title="Contacts" position="right">
                <Link
                  aria-label="contacts"
                  href="/contacts"
                  className={clsx(
                    "group flex h-10 w-10 items-center justify-center rounded-lg hover:bg-gray-100 focus-visible:outline focus-visible:outline-offset-0 focus-visible:outline-gray-900 group-hover:cursor-pointer",
                    {
                      "bg-gray-100": navigation[3].current,
                    }
                  )}
                >
                  <Icon
                    icon="address-book"
                    size="20"
                    iconStyle={`group-hover:fill-gray-900 ${
                      navigation[3].current ? "fill-gray-900" : "fill-gray-500"
                    }`}
                  />
                </Link>
              </Tooltip>
            </nav>
            <div className="mt-4 w-[60px] border-b border-gray-200" />
            <nav className="Navbar-SecondMenu mt-4 flex flex-col space-y-2 ">
              {showOnboarding && (
                <Tooltip title="Setup" position="right">
                  <Link
                    aria-label="setup"
                    href="/setup"
                    className={clsx(
                      "group flex h-10 w-10 items-center justify-center hover:rounded-lg hover:bg-gray-100 group-hover:cursor-pointer",
                      {
                        "rounded-lg bg-gray-100": navigation[0].current,
                      }
                    )}
                  >
                    <Icon
                      icon="check-circle"
                      size="20"
                      iconStyle="fill-gray-500 group-hover:fill-gray-800"
                    />

                    <div className="absolute right-0 top-0 h-3 w-3">
                      <Badge type="number" color="number" label="" />
                    </div>
                  </Link>
                </Tooltip>
              )}

              <Tooltip title="Invite a teammate" position="right">
                <div
                  className={clsx(
                    "group flex h-10 w-10 items-center justify-center hover:cursor-pointer hover:rounded-lg hover:bg-gray-100"
                  )}
                  data-testid="invite-button"
                  onClick={() => {
                    setOpenInviteDrawer(true);
                  }}
                >
                  <Icon
                    icon="user-circle-plus"
                    size="20"
                    iconStyle="fill-gray-500 group-hover:fill-gray-800"
                  />
                </div>
              </Tooltip>
              <Tooltip title="Activity log" position="right">
                <div
                  className={clsx(
                    "group flex h-10 w-10 items-center justify-center hover:cursor-pointer hover:rounded-lg hover:bg-gray-100"
                  )}
                  onClick={() => setShowActivity(true)}
                  data-testid="activity-drawer"
                >
                  <Icon
                    icon="notification"
                    size="20"
                    iconStyle="fill-gray-500 group-hover:fill-gray-800"
                  />
                </div>
              </Tooltip>
            </nav>
            <div className="mt-4 w-[60px] border-b border-gray-200" />
          </div>
          <nav className="Navbar-bottom flex flex-col items-center space-y-2 pb-8">
            <Tooltip title="Support" position="right">
              <div
                className={clsx(
                  "group flex h-10 w-10 items-center justify-center hover:cursor-pointer hover:rounded-lg hover:bg-gray-100"
                )}
                data-testid="support-button"
                onClick={() => {
                  window && window.Intercom && window.Intercom("show");
                }}
              >
                <Icon
                  icon="headset"
                  iconStyle="fill-gray-500 group-hover:fill-gray-800"
                />
              </div>
            </Tooltip>
            {showIntegrations && (
              <Tooltip
                title="Account integrations"
                subtitle={getERPText()}
                position="right"
              >
                <div
                  className={clsx(
                    "group relative flex h-10 w-10 items-center justify-center hover:cursor-pointer hover:rounded-lg hover:bg-gray-100"
                  )}
                  data-testid="integations-icon"
                  onClick={() => router.push("/profile/integrations")}
                >
                  <Icon
                    icon="arrows-clockwise"
                    iconStyle="fill-gray-500 group-hover:fill-gray-800"
                  />
                  <Icon
                    icon={erpUpToDate ? "check-circle-fill" : "warning-circle"}
                    size="18"
                    iconStyle={`absolute top-0 -right-1.5 ${
                      erpUpToDate ? "fill-green-500" : "fill-orange-600"
                    }`}
                  />
                </div>
              </Tooltip>
            )}

            <div className="flex h-10 w-10 items-center justify-center">
              <ContextMenu
                items={desktopMenuitems}
                position="right-end"
                padding={16}
                data-testid="navbar-desktop-user-icon"
              >
                <Avatar
                  user={user as UserType}
                  small
                  loading={!user}
                  className="hover:cursor-pointer"
                />
              </ContextMenu>
            </div>
          </nav>
        </div>
      </div>

      <div
        className="NavBar-Mobile fixed inset-x-0 z-20 flex h-[60px] items-center justify-between border-b border-gray-200 bg-gray-50 px-4 shadow-sm md:hidden"
        data-testid="mobile-navbar"
      >
        <ContextMenu
          items={mobileMenuItems}
          position="top-start"
          open={openMobileMenu}
          setOpen={setOpenMobileMenu}
          padding={16}
          buttonLabel="Menu"
          icon="list"
          labelStyle="text-base font-semibold"
          iconSize="20"
          iconStyle="fill-gray-900"
          buttonStyles="!flex-row-reverse items-center gap-1 flex-wrap-0 flex focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200 h-fit px-2 py-1 rounded-lg bg-transparent"
        />

        <div className="flex flex-row items-center">
          <Icon icon="bolt-gradient" size="32" />
          <span className="font-gilroy ml-3 text-2xl font-bold text-gray-900">
            Quickly
          </span>
        </div>
      </div>

      {openInviteDrawer && (
        <InviteTeammateModal
          open={openInviteDrawer}
          setOpen={setOpenInviteDrawer}
        />
      )}

      <ActivityDrawer open={showActivity} setOpen={setShowActivity} />
    </div>
  );
}
